<template>
  <div class="intro_container flex_column" v-if="basicInfo">
    <div class="desc_wrapper flex_column" :class="[theme=='light'?'desc_wrapper_light':'desc_wrapper_dark']">
      <div class="coin_desc " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
        <p :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicGlobalizationInfo.description}}</p>
      </div>
      <div class="coin_detail flex_column">
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('发行日期')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicInfo.issueTime | formateTime}}</p>
        </div>
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('最大供应量')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{thousands(basicInfo.maximumSupply)}}&nbsp;{{basicInfo.currencyCode}}</p>
        </div>
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('总供应量')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{thousands(basicInfo.totalSupply)}}&nbsp;{{basicInfo.currencyCode}}</p>
        </div>  
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']"> 
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('上架交易所')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicInfo.exchangeNum}}</p>
        </div>    
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('核心算法')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicGlobalizationInfo.coreAlgorithm}}</p>
        </div>    
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('奖励机制')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicGlobalizationInfo.incentiveSystem}}</p>
        </div>  
        <div class="detail_item flex_row " :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']">
          <p class="p_1" :class="[theme=='light'?'color_3_light':'color_3_dark']">{{$t('概念分类')}}</p>
          <p class="p_2" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{basicGlobalizationInfo.concept}}</p>
        </div>            
      </div>
    </div>
        <div class="relate_link flex_column" :class="[theme=='light'?'relate_link_light':'relate_link_dark']">
      <div class="title" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{$t('官网地址')}}</div>
      <div class="link_wrapper flex_row">
        <a class="link--item" :href="basicInfo.officialWebsiteUrl">
          {{basicInfo.officialWebsiteUrl}}
        </a>
      </div>
    </div>
    <div class="relate_link flex_column" :class="[theme=='light'?'relate_link_light':'relate_link_dark']">
      <div class="title" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{$t('白皮书')}}</div>
      <div class="link_wrapper flex_row">
        <a class="link--item" :href="basicInfo.whitePaperUrl">
          {{basicInfo.whitePaperUrl}}
        </a>
      </div>
    </div>
    <div class="relate_link flex_column" :class="[theme=='light'?'relate_link_light':'relate_link_dark']">
      <div class="title" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{$t('区块站')}}</div>
      <div class="link_wrapper flex_row">
        <div class="link_item flex_column" v-for="(item,index) in blockExplorerUrl" :key="index">
          <a class="item--explore" :href="item">{{$t('区块站')}}{{index+1}}</a>
        </div>
      </div>
    </div>
    <div class="relate_link flex_column" :class="[theme=='light'?'relate_link_light':'relate_link_dark']">
      <div class="title" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{$t('社交网站')}}</div>
      <div class="link_wrapper flex_row">
        <a class="link_item flex_column" :href="item.websiteUrl" v-for="(item,index) in socialWebsiteUrl" :key="index">
          <img v-if="item.logoUrl!=''" :src="item.logoUrl" alt="">
        </a>
      </div>
    </div>
    <!-- 发展路线 -->
    <div class="history flex_column" :class="[theme=='light'?'desc_wrapper_light':'desc_wrapper_dark']">
      <div class="title" :class="[theme=='light'?'color_2_light':'color_2_dark']">{{$t('发展历程和路线')}}</div>
      <div class="history_list flex_column">
        <div class="history_item flex_row_s" v-for="(item,index) in events" :key="index">
            <span></span>
            <div class="detail flex_column">
              <p>{{item.eventTime | formateTime}}</p>
              <div :class="[theme=='light'?'color_2_light':'color_2_dark']">
               {{item.eventDesc}}
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { coinDeil } from '@/api/index'
export default {
  data () {
    return {
      basicInfo:'',
      socialWebsiteUrl: [],
      basicGlobalizationInfo: {
      },
      events: [],
      currencyId: '',
      language: '',
      theme: '',
      blockExplorerUrl: []
    }
  },
  created () {
    this.currencyId = this.$route.query.currencyId
    this.language = localStorage.getItem('currentNation')
    this.theme = 'light';
    this.getData()
  },
  filters:{
    formateTime(value){
      return value.substring(0,11)
    }
  },
  methods: {
    getData () {
      coinDeil(this.currencyId).then(res => {
        // console.log(res, '基本信息')
        if (res.resultStatus) {
          this.basicInfo = res.resultData
          this.basicGlobalizationInfo = res.resultData.basicGlobalizationInfo[this.language]||{}
          this.socialWebsiteUrl = res.resultData.socialWebsiteUrl
          this.events = res.resultData.events[this.language]
          this.blockExplorerUrl = this.basicInfo.blockExplorerUrl.split(';');
        }
      })
    },
    // 数字转换为每三个数字就以逗号隔开
    thousands(num){
      var str = num.toString();
      var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg,"$1,");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import '../theme.scss';
.intro_container{
  width: 100%;
}
.desc_wrapper{
  padding: 0 32px;
  box-sizing: border-box;
  .coin_desc{
    font-size: 24px;
    color: #333333;
    padding: 30px 0px;
    p{
      width: 100%;
      white-space: pre-line;
    }
  }
  .detail_item{
    height: 88px;
    justify-content: space-between;
    position: relative;
    .p_1{
      font-size: 24px; 
    }
    .p_2 {
      font-size: 24px;
      position: relative;
    }
  }
}
.relate_link{
  width: 100%;
  .title {
    font-size: 32px;
    margin-top: 24px;
    font-weight: bold;
    margin-left: 32px;
  }
  .link_wrapper {
    margin-top: 34px; 
    flex-wrap: wrap;
    width: 100%;
    .link_item {
      width: 150px;
      position: relative;
      justify-content: center;
      align-items: center;
      margin-bottom: 44px;
      img{
        width: 48px;
        height: 48px;
        position: relative;
      }
      p{
        font-size: 20px;
        color: #333333;
        position: relative;
        margin-top: 10px;
      }
    }
    .link--item {
      margin-left: 32px;
      margin-bottom: 44px;
      text-decoration: none;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
    .item--explore {
      margin-left: 15px;
    }
    .link_item:nth-of-type(5n){
      margin-right: 0px;
    }
  }
}
.history{
  width: 100%;
  padding: 0px 32px;
  box-sizing: border-box;
  .title{
    font-size: 32px;
    font-weight: bold;
    margin-top: 24px;
  }
  .history_list{
    margin-top: 40px;
    .history_item {
      margin-bottom: 60px;
      span{
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 5px solid #007AFF;
        margin-right: 24px;
      }
      .detail{
        width: 95%;
        p {
          font-size: 24px;
          color: #007AFF;
          position: relative;
        }
        div {
          font-size: 24px;
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }

}
</style>

