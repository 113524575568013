<template>
  <div class="history flex_column">
    <div class="nav flex_row" :class="[theme=='light'?'nav_light':'nav_dark']">
      <div class="nav_item flex_row" v-for="(item,index) in navArr" :key="index" @click="onChange(index,item)">
         <p :class="[active==index?'active':'off']">{{item.label}}</p>
      </div>
    </div>
    <div class="table_wrapper flex_row_s">
      <div class="table_left flex_column">
          <div class="t_header flex_row" :class="[theme=='light'?'t_header_light':'t_header_dark']">
            {{$t('时间')}}
          </div>
          <div class="t_item flex_row" :class="[theme=='light'?'tl_item_light':'tl_item_dark']" v-for="(item,index) in historical" :key="index">
              {{item.countDate}}
          </div>
      </div>
      <div class="scroll_table">
        <div class="table_right flex_column">
          <div class="t_header_wrapper ">
            <div class="t_header flex_row" :class="[theme=='light'?'t_header_light':'t_header_dark']" v-for="(item,index) in tableHeader" :key="index">
                {{item.label}}
            </div>
          </div>
          <div class="t_item_wrapper" v-for="(htem,index) in historical" :key="index">
             <div class="t_item" :class="[theme=='light'?'t_item_light':'t_item_dark']"  v-for="(item,index) in tableHeader" :key="index">
               {{htem[item.value] | formateMoney}}
             </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hisotoryData } from '@/api/index'
export default {
  data () {
    return {
      active: 0,
      historical:[
        {
          countDate: '2018-9-15',
          amount: 1500,
          openPrice: 30,
          closePrice: 45,
          lowPrice: 56,
          highPrice: 69,
          volume: 155,
          marketCapVol: 30
        },
        {
          countDate: '2018-9-15',
          amount: 15055,
          openPrice: 352,
          closePrice: 49,
          lowPrice: 56,
          highPrice: 69,
          volume: 155,
          marketCapVol: 30
        }
      ],
      param: {
        startTime:'',
        endTime: ''
      },
      currencyId: '',
      theme: 'dark'
    }
  },
  filters: {
      formateMoney: function (value) {
          let currencyCode = localStorage.getItem('quoteCurrencyCode');
          let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
          let currencySymbol = {
            'CNY': '￥',
            'USD': '$',
            'KRW': '₩',
            'JPY': '￥'
          }
          let lastValue = '';
          let rate = priceConversion[currencyCode].currencyPrice;
          lastValue = (value * rate).toFixed(4);
          lastValue = parseFloat(lastValue)
          if(lastValue == 0.0000) {
            lastValue = 0
          }
          let covertValue = (value * rate);//转换后的值
           if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 100000000 > 1) {
            let localeValue = (covertValue / 100000000);
            localeValue = parseFloat(localeValue.toFixed(2));
            lastValue = `${localeValue}亿`
          } else if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 10000 > 1) {
            let localeValue = (covertValue / 10000);
            localeValue = parseFloat(localeValue.toFixed(2));
            lastValue = `${localeValue}万`
          } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
            let localeValue = (covertValue / 1000000000);
            localeValue = parseFloat(localeValue.toFixed(2));
            lastValue = `${localeValue}B`
          } else if (Number.parseInt(covertValue) / 1000000 > 1) {
            let localeValue = (covertValue / 1000000);
            localeValue = parseFloat(localeValue.toFixed(2));
            lastValue = `${localeValue}M`
          }
          let str = `${currencySymbol[currencyCode]}${lastValue}`;
          return str;
      },
  },
  computed: {
    navArr: function () {
      return [
        {
          label: this.$t('7天'),
          value: '7'
        },
        {
          label: this.$t('30天'),
          value: '1'
        },
        {
          label: this.$t('3月'),
          value: '3'
        },
        {
          label: this.$t('12月'),
          value: '12'
        }
      ]
    },
    tableHeader: function () {
      return [
        {
          label: this.$t('开盘价'),
          value: 'openPrice'
        },
        {
          label:  this.$t('收盘价'),
          value: 'closePrice'
        },
        {
          label: this.$t('最低价'),
          value: 'lowPrice'
        },
        {
          label: this.$t('最高价'),
          value: 'highPrice'
        },
        {
          label: this.$t('成交额'),
          value: 'volume'
        },
        {
          label: this.$t('流通市值'),
          value: 'marketCap'
        }
      ]
    }
  },
  created () {
    this.param.startTime = this.$moment().subtract(6, 'days').format('YYYY-MM-DD')
    this.param.endTime = this.$moment().format('YYYY-MM-DD')
    this.currencyId = this.$route.query.currencyId
    this.theme = 'light';
    this.getData()
  },
  methods: {
    onChange (index, item) {
      this.active = index
      if (index == 0) {
        this.param.startTime = this.$moment().subtract(6, 'days').format('YYYY-MM-DD')
        this.param.endTime = this.$moment().format('YYYY-MM-DD')
        this.getData()
      } else {
        this.param.startTime = this.$moment().subtract(item.value, 'months').format('YYYY-MM-DD')
        this.param.endTime = this.$moment().format('YYYY-MM-DD')
        this.getData()
      }
    },
    getData () {
      hisotoryData(this.currencyId, this.param).then(res => {
        if (res.resultStatus) {
          this.historical = res.resultData
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/common.scss";
@import '../theme.scss';
.nav {
  height: 120px;
  position: relative;
  width: 100%;
  .nav_item {
    width: 25%;
    height: 100%;
    justify-content: center;
    p {
      width: 80px;
      height: 40px;
      position: relative;
      font-size: 24px;
      text-align: center;
      line-height: 40px;
      border-radius: 3px;
    }
    .active{
      color: #fff;
      background: #007AFF;
    }
    .off{
      color: #666666;
    }
  }
}
.table_wrapper {
  width: 100%;
  position: relative;
  .table_left {
    width: 140px;
    position: relative;
    .t_header {
      height: 60px;
      position: relative;
      justify-content: center;
    }
    .t_item {
      width: 140px;
      position: relative;
      height: 88px;
      justify-content: center;
    }
  }
  .scroll_table {
    width: calc(100% - 140px);
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    background: #fff;
    .table_right {
      width: auto;
      position: relative;
      .t_header_wrapper{
        width: 100%;
        .t_header{
          height: 60px;
          display: inline-block;
          text-align: center;
          line-height: 60px;
          width: 283px;
          justify-content: center;
        }
      }
      .t_item_wrapper {
          width: 100%;
          height: 88px;
          .t_item {
            height: 88px;
            display: inline-block;
            text-align: center;
            line-height: 88px;
            width: 283px;
            justify-content: center;
          }
      }
   
    }
  }

}
</style>

