<template>
  <div class="chart_wrapper flex_column">
      <div class="period flex_row" :class="[theme=='light'?'bg_light':'bg_dark']">
        <div class="period_item flex_column"  v-for="(item,index) in periodArr" :key="index" @click="changePeriod(item)">
           <div :class="[active == item.key?'active':'off']" >{{item.label}}</div>
           <p :class="[item.value>0?'colorUp':'colorDown']">{{item.value}}%</p>
        </div>
      </div>
     <div id="chart_container"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import { dataArr } from './data.js'
import { coinQuotation } from '@/api/index'
import { setTimeout } from 'timers';
HighchartsMore(Highcharts)
export default {
  data() {
    return {
      chart: null,
      active: 'day',
      countDatas: "",
      theme: ''
    }
  },
  computed: {
    periodArr: function () {
      return [
        {
          label: this.$t('24H'),
          key: 'day',
          value: 0
        },
        {
          label: this.$t('一周'),
          key: 'week',
          value: 0
        },
        {
          label: this.$t('一月'),
          key: 'mon',
          value: 0
        },
        {
          label: this.$t('一年'),
          key: 'year',
          value: 0
        },
        {
          label: this.$t('所有'),
          key: 'all',
          value: 0
        }
      ]
    }
  },
  created() {
    this.currencyId = this.$route.query.currencyId
    this.theme = 'light';
    this.getData()
  },
  mounted() {

  },
  methods: {
    initChart (ohlc,volume) {
      const dataArr =  this.countDatas[this.active]
      Highcharts.setOptions({
        lang: {
          rangeSelectorZoom: ''
        }
      });
      // create the chart
      this.stockChart = new Highcharts.stockChart('chart_container', {
        navigator: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        scrollbar: {
          height: 15
        },
        yAxis: [{
          labels: {
            align: 'left',
            x: -3
          },
          title: {
            text: 'Price'
          },
          height: '60%',
          lineWidth: 2
        }, {
          labels: {
            align: 'left',
            x: -3
          },
          title: {
            text: '24h Vol'
          },
          top: '61%',
          height: '35%',
          offset: 0,
          lineWidth: 2
        }],
        series: [
          {
            type: 'area',
            color: '#007AFF',
            tooltip: {},
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            },
            data: ohlc
          }, {
            type: 'column',
            name: 'Volume',
            data: volume,
            yAxis: 1
          }]
      });
    },
    changePeriod (val) {
      this.active = val.key
      this.dealData()
    },
    getData () {
      coinQuotation(this.currencyId).then(res => {
        if (res.resultStatus) {
          const changePercentages = res.resultData.changePercentages
          for (let p of this.periodArr) {
            p.value = changePercentages[p.key].toFixed(2)
          }
          this.countDatas = res.resultData.countDatas
          this.dealData()
          this.$forceUpdate()
        }
      })
    },
    dealData () {
      const chartData = this.countDatas[this.active]
      //1， 处理价格 2，处理成交量
      let priceArr = [];
      let volumnArr = [];
      
      for (let l of chartData) {
        let date = new Date(l.countDate).valueOf();
        priceArr.push([date, l.quoteCurrencyPrice]);
        volumnArr.push([date, l.volume])
      }
      this.initChart(priceArr, volumnArr);
    },
    loadScropt () {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'http://cdn.highcharts.com.cn/highstock/themes/dark-unica.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
@import '../theme.scss';
#chart_container {
  width: 100%;
  height: 550px;
  position: relative;
  box-sizing: border-box;
}
.period {
  height: 120px;
  width: 100%;
  padding-left: 47px;
  box-sizing: border-box;
  justify-content: space-between;
  .period_item {
    width: 20%;
    margin-right: 50px;
    justify-content: center;
    div {
      width: 100%;
      height: 40px;
      font-size: 24px;
      position: relative;
      text-align: center;
      line-height: 40px;
      border-radius: 3px;
      white-space: nowrap;
      
    }
    p {
      font-size: 20px;
      margin-top: 3px;
      text-align: center;
      overflow: hidden;
    }
    .active {
      color: #fff;
      background: #007AFF;
    }
    .off {
      color: #777777;
    }
  }
}
</style>
