<template>
  <div class="kline">
    <div id="tradingview_e8b7">

    </div>
  </div>
</template>
<script>
const langObj = {
  ZH:'zh_CN',
  JA: 'ja',
  KO: 'kr',
  EN: 'en'
}
import { coinDeil } from '@/api/index'
export default {
  data () {
    return {
      symbol: '',
      symbol1: '',
      locale: '',
      theme: ''
    }
  },
  methods: {
    getDetail() {
      coinDeil(parseInt(this.$route.query.currencyId)).then(res => {
        if (res.resultStatus) {
          if(res.resultData.currencyPair) {
            this.symbol1 = res.resultData.currencyPair
          } else {
            this.symbol1 = ''
          }
          this.initChart()
        }
      });
    },
    initChart () {
    const tradeView  = new TradingView.widget(
          {
          "autosize": true,
          "width": 375,
		      "height": 310,
          "symbol": `${this.symbol1}` || `${this.symbol}`,
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": this.theme,
          "style": "1",
          "locale": this.locale,
          "toolbar_bg": "#f1f3f6",
          "enable_publishing": false,
          "save_image": false,
          "allow_symbol_change": true,
          "container_id": "tradingview_e8b7"
        }
     );
    }
  },
  mounted () {
    this.language = localStorage.getItem('currentNation')
    this.getDetail()
  },
  created () {
    this.locale = langObj[this.$i18n.locale]
    if (this.$route.query.symbol) {
      if(this.$route.query.symbol == 'FIL') {
        this.symbol = 'XFILUSD'
      } else if(this.$route.query.symbol == 'LEND') {
        this.symbol = 'LEND'
      } else {
        this.symbol = this.$route.query.symbol + 'USD'
      }
    }
    this.theme = 'Light'
  }
}
</script>

<style lang="scss" scoped>
.kline{
  width: 100%;
  height: 605px;
  position: relative;
}
#tradingview_e8b7{
  width: 100%;
  height: 605px;
  position: relative;
  box-sizing: border-box;
}
.tv-spinner {
  display: none !important;
}
</style>
