<template>
  <div class="market_container">
    <div class="table_header flex_row" :class="[theme=='light'?'table_header_light':'table_header_dark']">
      <div class="t_1">{{$t('交易所')}}</div>
      <div class="t_2">{{$t('最新价')}}</div>
      <div class="t_3">{{$t('24H成交额')}}</div>
    </div>
    <div 
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="5"
      class="table_content flex_column" :class="[theme=='light'?'bg_light':'bg_dark']">
      <div class="table_item flex_row" :class="[theme=='light'?'border_bottom_light':'border_bottom_dark']" v-for="(item, index) in exchangeArr" :key="index">
        <div class="t_1 flex_row">
          <img :src="item.exchangeLogoUrl" alt="">
          <div class="coin_name flex_column">
            <p>{{item.exchangeName}}</p>
            <p>{{item.baseCurrencyCode}}/{{item.quoteCurrencyCode}}</p>
          </div>
        </div>
        <div class="t_2">
          {{item.price | formateMoney}}
        </div>
        <div class="t_2">
          <!-- :class="[item.increaseRate>0?'colorUp':'colorDown','t_3']" -->
          {{item.volume24 | formateMoney}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { marketCondition } from '@/api/index'
import { Toast } from 'mint-ui'
export default {
  data () {
    return {
      exchangeArr: [],
      param: {
        baseCurrencyId: '',
        quoteCurrencyCode: '',
        language: '',
        pageNo: 1,
        pageSize: 20
      },
      theme: ''
    }
  },
  created () {
    this.param.baseCurrencyId = this.$route.query.currencyId
    this.param.language = localStorage.getItem('currentNation') || this.$i18n.locale
    this.theme = 'light'
    this.getData()
  },
  filters: {
    formateMoney: function (value) {
      let currencyCode = localStorage.getItem('quoteCurrencyCode');
      let priceConversion = JSON.parse(localStorage.getItem('priceConversion'));
      let currencySymbol = {
        'CNY': '￥',
        'USD': '$',
        'KRW': '₩',
        'JPY': '￥'
      }
      let lastValue = '';
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue)
      if(lastValue == 0.0000) {
        lastValue = 0
      }
      let covertValue = (value * rate);//转换后的值
      if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 100000000 > 1) {
        let localeValue = (covertValue / 100000000);
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}亿`
      } else if (currencyCode == 'CNY' && Number.parseInt(covertValue) / 10000 > 1) {
        let localeValue = (covertValue / 10000);
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}万`
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = (covertValue / 1000000000);
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}B`
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = (covertValue / 1000000);
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}M`
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    toDecimal: function (value) {
      if(value){
        return parseFloat(value.toFixed(2))
      }else {
        return 0
      }
    }
  },
  methods: {
    getData () {
      marketCondition(this.param).then( res => {
        if (res.resultStatus) {
          const dataList = res.resultData.dataList
          if (dataList.length > 0) {
            this.param.pageNo = this.param.pageNo + 1
          } else {
           this.param.pageNo>0?Toast(this.$t('没有更多了')):''
          }
          this.exchangeArr = this.exchangeArr.concat(dataList)
        }
      })
    },
    loadMore () {
      if (this.param.pageNo > 1) {
         this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../theme.scss';
@import "@/styles/common.scss";
.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.market_container{
  width: 100%;
  position: relative;
  .table_header{
    height: 64px;
    width: 100%;
    padding: 0px 40px;
    box-sizing: border-box;
    .t_1{
      width: 50%;
      font-size: 24px;
      position: relative;
    }
    .t_2 {
      width: 25%;
      font-size: 24px;
    }
    .t_3{
      width: 25%;
      font-size: 24px;
      // text-align: right;
    }
  }
  .table_content{
    width: 100%;
    align-items: flex-end;
    .table_item {
      width: 95%;
      height: 130px;
      box-sizing: border-box;
      padding-right: 40px;
      .t_1 {
        width: 50%;
        img{
          width: 80px;
          height: 80px;
          position: relative;
          margin-right: 20px;
        }
        .coin_name {
          height: 80px;
          justify-content: space-between;
          p:nth-child(1) {
            font-size: 24px;
            color: #666666;
          }
          p:nth-child(2) {
            font-size: 24px;
            color: #007AFF;
          }
        }
      }
      .t_2 {
        width: 25%;
        font-size: 24px;
        color: #666;
      }
      .t_3 {
        font-size: 24px;
        width: 25%;
        padding-right: 32px;
        box-sizing: border-box;
        text-align: right;
      }
    }
  }
}
</style>

